$custom-colors: (
	'njd': #010101,
	'nyi': #003087,
	'nyr': #0133a0,
	'phi': #010101,
	'pit': #010101,
	'bos': #010101,
	'buf': #003087,
	'mtl': #011e62,
	'ott': #010101,
	'tor': #00205a,
	'car': #010101,
	'fla': #041e42,
	'tbl': #002868,
	'wsh': #c7102e,
	'chi': #010101,
	'det': #c7102e,
	'nsh': #041e42,
	'stl': #041e42,
	'cgy': #c7102e,
	'col': #6e263d,
	'edm': #ce4520,
	'van': #00205b,
	'ana': #000,
	'dal': #000000,
	'lak': #000000,
	'sjs': #006d75,
	'cbj': #002654,
	'min': #154734,
	'wpg': #041e42,
	'ari': #8c2633,
	'vgk': #000,
	'sea': #001628,
);

.hero-media {
	border: 1px solid white;
}

.App {
	font-size: 2rem;
	padding: 2rem;
	text-align: center;
	color: white;
	height: calc(100vh - 52px);

	.date {
		margin-top: 1em;
		font-size: 0.5em;
	}

	.preseason-label {
		font-size: 0.4em;
	}

	.opponent {
		margin-top: 1em;
		font-size: 0.75em;
	}

	@media (min-width: 600px) {
		.logo {
			width: 600px;
			margin: auto;
		}
	}
}

@import 'bulma/sass/base/generic.sass';
@import 'bulma/sass/base/minireset.sass';
@import 'bulma/sass/utilities/_all.sass';

@import 'bulma/sass/layout/footer.sass';
@import 'bulma/sass/layout/hero.sass';
@import 'bulma/sass/grid/columns.sass';
@import 'bulma/sass/elements/button.sass';
@import 'bulma/sass/elements/container.sass';
@import 'bulma/sass/elements/title.sass';
@import 'bulma/sass/elements/content.sass';
@import 'bulma/sass/helpers/typography.sass';
@import 'bulma/sass/components/navbar.sass';
@import 'bulma/sass/elements/notification.sass';
@import 'bulma/sass/elements/other.sass';
@import 'bulma/sass/helpers/spacing.sass';
@import 'bulma/sass/components/dropdown.sass';
@import 'bulma/sass/form/shared.sass';
@import 'bulma/sass/form/tools.sass';
@import 'bulma/sass/form/input-textarea.sass';
@import 'bulma/sass/helpers/color.sass';
